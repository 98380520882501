.button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  width: 292px;
  height: 50px;
  background: inherit;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  font-weight: var(--font-primary-bold);
  font-size: var(--font-size-md);
  text-align: center;
  text-decoration: none;
  outline-style: none;
  margin-top: 15px;
  margin-bottom: 230px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &Secondary {
    @extend .button;

    background: var(--color-white);
    color: var(--color-gray);
    margin-bottom: 10px;
  }
}
