@keyframes slideIn {
  from {
    max-width: 1020px;
  }

  to {
    max-width: 100%;
  }
}

.modal {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__innerWrapper {
    max-width: 100%;
    height: 500px;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: 660px) {
      width: 610px;
      height: 545px;
      padding-left: 70px;
      padding-right: 70px;
      margin-top: 70px;
    }

    @media screen and (min-width: 996px) {
      position: absolute;
      right: 0;
    }
  }

  &__paragraph {
    padding-left: 0;
    padding-right: 0;
    font-weight: var(--font-primary-bold);
    font-size: 1.8rem;
    line-height: 3rem;
    color: var(--color-white);
    text-align: center;
    margin-bottom: 3.5rem;
  }

  &__photo {
    width: 220px;
    height: 220px;
    margin-bottom: 2.5rem;
    border-radius: 50%;
    border: 3px solid #ffffff;
  }
}
