.container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.section {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.box {
  width: 1920px;
  min-height: 100vh;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &Fixed {
    @extend .image;

    position: fixed;
  }
}
