@keyframes slideIn {
  from {
    transform: scale(0.7);
  }

  to {
    transform: scale(1);
  }
}

.verification {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__form {
    display: flex;
    flex-direction: column;

    &Input {
      width: 195px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid var(--color-white);
      color: var(--color-white);
      background-color: inherit;
      font-weight: var(--font-primary-bold);
      font-size: var(--font-size-md);
      line-height: var(--line-height-md);
      letter-spacing: 10px;
      padding-left: 28px;
      padding-right: 28px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      &Error {
        @extend .verification__formInput;

        border: 1px solid red;
      }

      &:focus {
        outline-style: inherit;
        border: 1px solid var(--color-blue);
      }
    }
  }

  &__inputBox {
    margin: 50px auto 70px auto;
  }

  &__status {
    height: 28px;

    &Bird {
      margin-right: 5px;
    }

    &Ok {
      display: flex;
      justify-content: center;
      color: var(--color-blue);
      font-size: var(--font-size-s);
      line-height: var(--line-height-mx);
      font-weight: var(--font-primary-bold);
    }

    &Failed {
      @extend .verification__statusOk;

      color: red;
    }
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    text-align: center;

    &Bold {
      @extend .verification__paragraph;

      font-weight: var(--font-primary-bold);
    }
  }

  &__info {
    max-width: 290px;
    margin: 0 auto 84px auto;

    &Paragraph {
      font-weight: var(--font-primary-regular);
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);
      margin-bottom: 15px;

      &Bold {
        @extend .verification__infoParagraph;

        margin-bottom: 0;
        font-weight: var(--font-primary-bold);
      }
    }
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    text-align: center;
    margin-bottom: 10px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  &__arrowLeft {
    position: relative;
    right: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      right: 40px;
    }
  }

  &__arrowRight {
    position: relative;
    left: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      left: 40px;
    }
  }
}
